import React, {useEffect, useState, useRef} from "react";
import { Link, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { LogoSite } from "../Components"; //LanguageDropdownSelector
import { DiscoverIcon, MessageIcon, StarIcon, UserIcon } from "../AllImages"; //HomeIcon
import {DOMAIN_URL} from "../../constant/Keys"
import config from '../../config.json';

const Nav = ({lang}) => {
  const { user } = useSelector((state)=>state.auth);
  const { pathname } = useLocation();
  const [socketConnectInterval, setSocketConnectInterval] = useState(null);
  const socketRef = useRef(null);
  const [unreadMsgCount, setUnreadMsgCount] = useState(0);
  const [unreadCountInterval, setUnreadCountInterval] = useState(null);

  const messagelink = (user?.paid == 1 || user?.gender == 'female') ? '/inbox?u='+user.id : '/inbox?u='+user.id;


  const socketConnect = () => {
    if(socketRef.current){
      return;
    }
    const token = localStorage.datingAppauthToken;
    if(token){
      let tmpSocket = new WebSocket(`${config.websocket}?token=${token}`);
      tmpSocket.onopen = (connection) => {
        console.log("Connected to server");
        if(unreadCountInterval){
          clearInterval(unreadCountInterval);
        }
        setUnreadCountInterval(setInterval(() => {
          tmpSocket.send(JSON.stringify({type: 'unread_messages'}));
        }, 10000));
        clearInterval(socketConnectInterval);
        tmpSocket.send(JSON.stringify({type: 'unread_messages'}));
      };
      tmpSocket.onerror = (error) => {
        console.log("Error in connection establishment", error);
        // wait 5 seconds and try again
        if(socketConnectInterval) {
          clearInterval(socketConnectInterval);
        }
        setSocketConnectInterval(setInterval(function () {
          socketConnect();
        }, 5000));
      };
      tmpSocket.onclose = (event) => {
        console.log("Socket Closed Connection: ", event);
        // wait 5 seconds and try again
        setTimeout(function () {
          socketConnect();
        }
        , 5000);
      };
      tmpSocket.onmessage = (e) => {
        const data = JSON.parse(e.data);
        if(data.type==='unread_messages'){
          setUnreadMsgCount(data.unread_messages);
        }
      }
      // update SocketRef
      socketRef.current = tmpSocket;
    }else{
      // wait 10 seconds and try again
      console.log("No token found");
      setTimeout(function () {
        socketConnect();
      }, 10000);
    }  
  }

  useEffect(() => {
    socketConnect();
  }, []);

  return (
    <>
      <header className="container-fluid d-none d-md-flex">
        <div className="logo color">
          <a href="/home">
            <LogoSite height="45px" />
          </a>
        </div>
        <nav className="nav">
          <ul>
            <li className={`d-md-block d-none ${pathname==="/home"?"active":""}`}>
              <Link to="/home">
                <DiscoverIcon width="22px" height="22px"  style={{marginTop:"3px"}} />&nbsp;
                <span className="text-capitalize">{lang.home}</span>
              </Link>
            </li>
            <li className={`d-md-block d-none ${pathname=== "/inbox" ? "active" : ""}`}>
              <Link to={ messagelink }>
                <MessageIcon width="20px" height="19.5px" style={{marginTop:"5px"}} />&nbsp;
                {/* {unreadMsgCount>0?
                  <span className="unread d-flex align-items-center justify-content-center">
                    {unreadMsgCount}
                  </span>
                :null  
                } */}
                <span className="text-capitalize" style={{display:"contents"}}>
                  {lang.messages}
                  {unreadMsgCount>0?
                    <span className="unread d-flex align-items-center justify-content-center" style={{
                        background: "linear-gradient(180deg,#dbae2a 0%,#cf8d2d 100%)",
                        width: "28px",
                        height: "28px",
                        borderRadius: "50%",
                        fontWeight: "500",
                        fontSize: "14px",
                        lineHeight: "19px",
                        textAlign: "center",
                        color: "#ffffff",
                        marginLeft: "0.5em",
                        marginRight: "0.5em"
                      }}>
                      {unreadMsgCount}
                    </span>
                  :null  }
                  {/* {(unreadMsgCount>0)?` (${unreadMsgCount})`:""} */}
                </span>
              </Link>
            </li>
            {/* <li className={`${pathname==="/discover"?"active":""}`}>
              <Link to="/discover">
                <i className="ri-compass-discover-line"></i>&nbsp;
                <span className="text-capitalize">{lang.discover}</span>
              </Link>
            </li> */}
            <li className={`d-md-block d-none ${pathname==="/wishlist"?"active":""}`}>
              <Link to="/wishlist">
                <StarIcon width="20px" height="20px" />&nbsp;
                <span className="text-capitalize">{lang.favourite}</span>
              </Link>
            </li>
            <li className={`d-md-block d-none ${pathname===`/profile/${user?.id}`?"active":""}`}>
              <Link to={`/profile/${user?.id}`}>
                <UserIcon width="16px" height="19px" />&nbsp;
                <span className="text-capitalize">{lang.profile}</span>
              </Link>
            </li>
            <li className={`${pathname==="/settings"?"active":""}`}>
              <Link to={`/settings`}>
                <i className="ri-settings-4-fill"></i>
                <span className="text-capitalize">{lang.settings}</span>
              </Link>
            </li>
            {user?.gender==="male" && (user?.paid===0 || user?.canceled===1)?
              <li className="d-md-block d-none" style={{alignItems:"center"}}>
                <Link style={{justifyContent: 'center'}} to="/upgrade" className="btn-center">
                  {lang.upgrade_premium}
                </Link>
              </li>
            :null}
          </ul>
        </nav>
      </header>
      <div className="mobile-nav d-md-none d-block">
        <ul className="d-flex flex-wrap list-unstyled align-items-center h-100 px-3 justify-content-between">
          <li className={`${pathname==="/home"?"active":""}`}>
            <Link to="/home" className="d-flex">
              <DiscoverIcon />
            </Link>
          </li>
          <li className={`${pathname=== "/inbox" ? "active" : ""}`}>
            <Link className="d-flex" to="/inbox">
              <MessageIcon />
              {unreadMsgCount>0?
                    <span className="unread d-flex align-items-center justify-content-center" style={{
                        background: "linear-gradient(180deg,#dbae2a 0%,#cf8d2d 100%)",
                        width: "28px",
                        height: "28px",
                        borderRadius: "50%",
                        fontWeight: "500",
                        fontSize: "14px",
                        lineHeight: "19px",
                        textAlign: "center",
                        color: "#ffffff",
                        position: "absolute",
                        right: "-1.3em",
                        top: "-1.3em"
                      }}>
                      {unreadMsgCount}
                    </span>
                  :null  }
            </Link>
          </li>
          <li className={`${pathname==="/wishlist"?"active":""}`}>
            <Link className="d-flex" to="/wishlist">
              <StarIcon />
            </Link>
          </li>
          <li className={`${pathname===`/profile/${user?.id}`?"active":""}`}>
            <Link className="d-flex" to={`/profile/${user?.id}`}>
              <UserIcon />
            </Link>
          </li>
          <li className={`${pathname==="/settings"?"active":""}`}>
            <Link  to={`/settings`} className="d-flex">
              <i className="ri-settings-4-fill" style={{fontSize:"29px"}}></i>
            </Link>
          </li>
        </ul>
      </div>
    </>
  );
};


export default Nav;