import React, { useState, useRef } from 'react'
import styled from 'styled-components';
import { Box, Label } from '../components/Style'
import { useForm } from "react-hook-form"; // Controller
import { Row, Col, Form, Badge, Button } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import moment from "moment";
import HebrewLanguage from '../constant/langauges/HebrewLanguage';
import EnglishLanguage from '../constant/langauges/EnglishLanguage';
import Subscription from '../components/services/Subscription';
import { useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import { nameUpdate, getTransactions, updatePassword, getSingleUser } from '../constant/api/Api';
import { toast } from 'react-toastify';
import { LanguageDropdownSelector, LogoSite } from '../components/Components';
import { Link } from 'react-router-dom';
import Swal from "sweetalert2";
import { deleteMyAccountApi } from "../constant/api/Api";

const UserSettings = ({ logout }) => {
    const [show, setShow] = useState(false);
    const [paymentRecord, setPaymentRecord] = useState([]);
    const [emailError, setEmailError] = useState(false);
    const [emailLoader, setEmailLoader] = useState(false);
    const [userStatus, setUserStatus] = useState('');
    const { langauge } = useSelector((state) => state.clients);
    const { user } = useSelector((state) => state.auth);
    const lang = langauge === "heb" ? HebrewLanguage : EnglishLanguage;
    const [tabs, setTabs] = useState('user');
    const { register, handleSubmit, reset, control, watch, formState: { errors } } = useForm();
    const [email_edit, setEmailEdit] = useState(true);
    const inputRef = useRef(null);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [del_loader, setDelLoader] = useState(false);



    const onSubmit = async (data) => {
        setEmailLoader(true);
        try {
            const res = await updatePassword(data);
            reset();
            toast.success(res.data.message);
            const timer = setTimeout(() => {
                handleClose()
            }, 1000);
            return () => clearTimeout(timer);
        }
        catch (error) {

        }
        setEmailLoader(false);
    };



    useEffect(async () => {
        try {
            const res = await getTransactions();
            setPaymentRecord(res.data);
            const single = await getSingleUser(user.id);
            setUserStatus(single.data.status);
        }
        catch (error) {
            console.log("Err")
        }
    }, [])


    const deleteMyAccount = async () => {

        // Swal get field value

        let delete_consequences = lang.delete_account_consequences;

        Swal.fire({
            title: lang.delete_account,
            text: lang.delete_account_message,
            input: 'password',
            confirmButtonText: lang.yes_delete,
            cancelButtonColor: '#d7a940',
            confirmButtonColor: '#6e7881',
            cancelButtonText: lang.no_cancel,
            showCancelButton: true
        }).then((resultTxt) => {
            if (resultTxt.value && resultTxt.value.length > 4) {
                // console.log("Result: " + result.value);
                Swal.fire({
                    html: delete_consequences,
                    timerProgressBar: true,
                    showCancelButton: true,
                    confirmButtonText: lang.yes_delete,
                    cancelButtonColor: '#d7a940',
                    confirmButtonColor: '#6e7881',
                    cancelButtonText: lang.no_cancel,
                    reverseButtons: true,
                    icon: "question"
                }).then(async (result) => {
                    if (result.isConfirmed) {
                        Swal.showLoading()
                        deleteMyAccountApi(resultTxt.value).then((res) => {
                            console.log("res", res.data)
                            if (res.data.success === false) {
                                toast.error(res.data.message);
                            } else {
                                toast.success(lang.account_deleted_text);
                                setTimeout(() => {
                                    console.log("logout")
                                    logout(true)
                                }, 10000);
                            }
                        })
                        // window.location.href = "/";
                    } else if (
                        result.dismiss === Swal.DismissReason.cancel
                    ) {

                    }
                })

            }
        });

        return false;

    }

    useEffect(() => {
        inputRef.current.focus();
    }, [email_edit])


    const updateEmailAction = async () => {
        if (inputRef.current.value && inputRef.current.value.length > 5) {
            setEmailLoader(true);
            try {
                const res = await nameUpdate({ name: inputRef.current.value });
                if (res.data.success == false) {
                    toast.error(res.data.message);
                } else {
                    // const userUpdate = user;
                    // userUpdate.email = inputRef.current.value;
                    toast.success(res.data.message);
                }
                inputRef.current.value = res.data.data.name;
                setEmailEdit(true);
            }
            catch (error) {
                toast.success('משהו השתבש');
            }
        } else {
            setEmailError(true)
        }
        setEmailLoader(false);
    }
    const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
            confirmButton: 'btn btn-success',
            cancelButton: 'btn btn-danger'
        },
        buttonsStyling: false
    })
    const DeleteMessages = async () => {
        Swal.fire({
            html: lang.logoutCnfrm,
            timerProgressBar: true,
            showCancelButton: true,
            confirmButtonText: lang.logout,
            confirmButtonColor: '#d7a940',
            cancelButtonText: lang.no_cancel,
            reverseButtons: true,
            icon: "question"
        }).then(async (result) => {
            if (result.isConfirmed) {
                Swal.showLoading()
                logout(true)

                Swal.hideLoading()
                // reload window after logout
                window.location.href = "/";
            } else if (
                result.dismiss === Swal.DismissReason.cancel
            ) {

            }
        })
    }
    const inputstyle = langauge === 'eng' ? {} : { direction: "rtl" };
    const errostyle = langauge === 'eng' ? {} : { direction: "rtl", marginTop: '15px' };
    return (
        <main>
            <Box className="container card__res_padding pt-4 pt-md-0">
                <div className="logo color mb-4 text-center d-md-none">
                    <LogoSite height="55px" />
                </div>
                <Box className="col-lg-10 col-xl-8 mx-auto">
                    <SettingsStyledContainer>
                        <Box className="tab-container">
                            <ul className="d-flex list-unstyled m-0 text-center p-0">
                                <li className={`${user?.gender === "female" ? "w-100" : ""} ${tabs === "user" ? "active" : ""}`} onClick={() => setTabs('user')}>
                                    {lang.settings}
                                </li>
                                {user?.gender !== "female" ?
                                    <li className={tabs === "sub" ? "active" : ""} onClick={() => setTabs('sub')}>
                                        {lang.subscription}
                                    </li>
                                    : null}
                            </ul>
                        </Box>
                        <Box className="px-md-4 px-3 pt-md-4 pt-3">
                            {tabs !== "sub" ?
                                <div className="signup-content">
                                    <Row>
                                        <Col md={12}>
                                            <div className="editable__box mb-3">
                                                <ul className="d-flex align-items-center p-0 m-0 list-unstyled">
                                                    <li>
                                                        <label className={`${langauge === "heb" ? "ms-lg-5 ms-2" : "me-lg-5 me-3"}`}>{lang.username}</label>
                                                    </li>
                                                    <li>
                                                        <input type="text" style={{ width: '120%' }} readOnly={email_edit} ref={inputRef}
                                                            placeholder={lang.name} className='border-0 bg-white'
                                                            defaultValue={user?.name} disabled={emailLoader}
                                                        />
                                                    </li>
                                                    <li className={`${langauge === "heb" ? "me-auto" : "ms-auto me-0 me-sm-3"} d-flex align-items-center`}>
                                                        {email_edit ?
                                                            <button type="button" disabled={emailLoader} onClick={() => setEmailEdit(false)} className="ms-auto border-0 bg-transparent">
                                                                <i className="ri-edit-line"></i>&nbsp;&nbsp;
                                                                <span className="d-none d-sm-inline">{lang.edit}</span>
                                                            </button>
                                                            :
                                                            <div className={`flex position-absolute ${langauge === "heb" ? "text-start" : "text-end"}`} style={{ right: langauge == "heb" ? "auto" : "0px", left: langauge == "heb" ? "0px" : "auto", width: "100px" }}>
                                                                <button type="button" disabled={emailLoader} onClick={() => { inputRef.current.value = user?.email; setEmailEdit(true) }} className="border-0 bg-transparent p-0" style={{ color: 'gray' }}>
                                                                    <i className="ri-close-circle-fill" style={{ fontSize: "30px", fontWeight: "inherit" }}></i>
                                                                </button>
                                                                <button type="button" onClick={() => updateEmailAction()} disabled={emailLoader} className="border-0 bg-transparent p-0" style={{ color: '#CF982B' }}>
                                                                    <i className="ri-checkbox-circle-fill" style={{ fontSize: "30px", fontWeight: "inherit" }}></i>
                                                                </button>
                                                            </div>
                                                        }
                                                    </li>
                                                </ul>
                                            </div>
                                            {emailError ?
                                                <span className="d-block px-1 text-danger error__text">{lang.validations.username_required}</span>
                                                : null}
                                            <div className="editable__box mb-3">
                                                <ul className="d-flex align-items-center p-0 m-0 list-unstyled">
                                                    <li>
                                                        <label className={`${langauge === "heb" ? "ms-lg-5 ms-3" : "me-lg-5 me-3"}`}>{lang.email}</label>
                                                    </li>
                                                    <li>
                                                        <input type="text" readOnly={true} className='border-0 bg-white'
                                                            defaultValue={user?.email} placeholder={lang.name} style={{ width: 'inherit' }}
                                                        />
                                                    </li>
                                                </ul>
                                            </div>
                                            <div>
                                                {/* <Label className='mt-3 mb-4'>{lang.settings}</Label> */}
                                            </div>


                                            <div className="editable__box mb-3">
                                                <ul className="d-flex align-items-center p-0 m-0 list-unstyled">
                                                    <li>
                                                        <label className={`${langauge === "heb" ? "ms-lg-5 ms-2" : "me-lg-5 me-3"}`}>{lang.password}</label>
                                                    </li>
                                                    <li>
                                                        <input type="password" defaultValue={"............"} readOnly={true} placeholder={lang.password} className='border-0 bg-white' />
                                                    </li>
                                                    <li className={`${langauge === "heb" ? "me-auto" : "ms-auto me-0 me-sm-3"} d-flex align-items-center`}>
                                                        <button type="button" disabled={emailLoader} onClick={() => handleShow(true)} className="ms-auto border-0 bg-transparent">
                                                            <i className="ri-edit-line"></i>&nbsp;&nbsp;
                                                            <span className='d-none d-sm-inline'>{lang.edit}</span>
                                                        </button>
                                                    </li>
                                                </ul>
                                            </div>
                                        </Col>


                                        {user?.gender !== "female" ?
                                            <Col md={10}>
                                                <Label className='mt-3'>{lang.subscription_status}:
                                                    <Badge className={`${user?.paid !== 1 ? 'bg-primary-badge' : null} ${langauge === "heb" ? "me-2" : "ms-2"}`}>
                                                        {userStatus}
                                                    </Badge>
                                                </Label>
                                                <br></br>
                                                {user?.paid === 0 ? "" :
                                                    <Label className='mt-3'>{lang.subscription_expiry}:
                                                        <Badge className={`${user?.paid !== 1 ? 'bg-primary-badge' : null} ${langauge === "heb" ? "me-2" : "ms-2"}`}>
                                                            {moment(user.subscription_expiry).format('DD-MM-YYYY')}
                                                        </Badge>
                                                    </Label>
                                                }
                                            </Col>
                                            : null}
                                        <Col md={2}>
                                            <br />
                                            <span onClick={deleteMyAccount} className='mt-3' style={{ fontSize: "0.75em", cursor: "pointer", color: "gray" }}>{lang.delete_my_account_text}
                                            </span>
                                        </Col>
                                    </Row>
                                </div>
                                :
                                <Subscription lang={lang} langauge={langauge} payments={paymentRecord} />
                            }
                            <ul className='list-unstyled bg__settings__bar px-md-4 px-3 py-2 justify-content-between'>
                                <li>
                                    <LanguageDropdownSelector lang={lang} />
                                </li>
                                <li>
                                    {/* <li> */}
                                    <button className='border-0 p-0 delete_messages_btn bg-transparent d-flex align-items-center' style={{ color: 'white' }} disabled={del_loader} onClick={() => DeleteMessages()}>
                                        <i className="ri-logout-circle-line" style={{ fontSize: "20px" }}></i>&nbsp;
                                        <span className="text-capitalize">{lang.logout}</span>
                                    </button>
                                </li>
                                {/* <Link to="#" className='text-white d-flex align-items-center' onClick={()=>logout(true)}>
                    <i className="ri-logout-circle-line" style={{fontSize:"20px"}}></i>&nbsp;
                    <span className="text-capitalize">{lang.logout}</span>
                  </Link> */}
                                {/* </li> */}
                            </ul>
                        </Box>
                    </SettingsStyledContainer>
                </Box>
            </Box>
            <Modal show={show} onHide={handleClose} className="popup__modal">
                <Form onSubmit={handleSubmit(onSubmit)}>
                    <Modal.Header closeButton style={inputstyle}>
                        {langauge === "heb" ? (
                            <Modal.Title className='modal-title-right'><i className="ri-edit-line"></i>&nbsp;{lang.edit_password}</Modal.Title>
                        ) : (
                            <Modal.Title className='modal-title-left'><i className="ri-edit-line"></i>&nbsp;{lang.edit_password}</Modal.Title>
                        )}
                    </Modal.Header>
                    <Modal.Body>
                        <div className={`modal-field modal-body-${langauge === "heb" ? "right" : "left"}`}>
                            {/* className={`justify-content-${langauge==="heb"?"end":"start"}` */}
                            <div className="input">
                                <input style={inputstyle} className={`input-${langauge === "heb" ? "right" : "left"}`} type="password" disabled={emailLoader}  {...register('old_password', { required: lang.validations.current_required, minLength: { value: 6, message: lang.validations.password_min } })} placeholder={lang.current_password} />
                                {errors.old_password && <span className="d-block px-1 text-danger error__text mb-3" style={errostyle}>{errors.old_password.message}</span>}
                            </div>
                            <div className="input">
                                <input style={inputstyle} className={`input-${langauge === "heb" ? "right" : "left"}`} type="password" disabled={emailLoader} {...register('new_password', { required: lang.validations.new_password, minLength: { value: 6, message: lang.validations.password_min } })} placeholder={lang.new_password} />
                                {errors.new_password && <span className="d-block px-1 text-danger error__text mb-3" style={errostyle}>{errors.new_password.message}</span>}
                            </div>
                            <div className='input'>
                                <input style={inputstyle} className={`input-${langauge === "heb" ? "right" : "left"}`} type="password" disabled={emailLoader} {...register('confirm_password', {
                                    required: lang.validations.confirm_password, minLength: { value: 6, message: lang.validations.password_min },
                                    validate: (val) => {
                                        if (watch('new_password') !== val) {
                                            return lang.validations.confirm_password;
                                        }
                                    },
                                })}
                                    placeholder={lang.c_password}
                                />
                                {errors.confirm_password && <span className="d-block px-1 text-danger error__text mb-3" style={errostyle}>{errors.confirm_password.message}</span>}
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer className="modal__footer justify-content-center">
                        <Button variant="primary" disabled={emailLoader} type="submit">{lang.save_changes}</Button>
                        {/* <Button variant="secondary" disabled={emailLoader} type="butt" onClick={handleClose}>Close</Button> */}
                    </Modal.Footer>
                </Form>
            </Modal>
        </main>
    )
}

const SettingsStyledContainer = styled.div`
  border:1px solid #dee2e6;
  border-radius: 10px;
  overflow: hidden;
  background: #fff;
  .bg-primary-badge{
    background: rgba(var(--bs-primary-rgb),var(--bs-bg-opacity))!important;
  }
  .bg__settings__bar{
    background-color: #000000;
    margin-top:30px;
    display: flex;
    align-items: center;
    @media(min-width:786px){
      margin-left:-1.5rem;
      margin-right:-1.5rem;
    }
    @media(max-width:767px){
      margin-left:-1rem!important;
      margin-right:-1rem!important;
    }
  }
  .align-items-right{
    display: flex;
    flex-direction:row;
    justify-content:center;
    align-items:flex-start;
    text-align:right;
    background-color:blue !important;

  }
  .align-items-left{
    display: flex;
    flex-direction:row;
    justify-content:center;
    align-items:flex-start;
    text-align:left;
    background-color:red !important;
  }

  .align-item-div{
    text-align:right;
    justify-content:flex-end;
  }
  .custom-btn{
    background-color:red !important;
  }

  .editable__box{
    background: #fff;
    border:1px solid #dee2e6;
    padding:17px 20px;
    border-radius:10px;
    min-height: 50px;
    box-shadow:0px 0px 12px 0px rgb(0 0 0 / 12%);
    @media(max-width:575px){
        padding:15px 12px;
    }
    ul li{
      label{
        font-weight:700;
        text-transform: capitalize;
        font-size:14px;
        color:#1a1720;
        width:75px;
        @media(max-width:575px){
          width:auto;
        }
      }
      input{
        font-weight:600;
        font-size:13px;
      }
      button{
        color: #5d7dc7;
        font-size: 14px;
        font-weight: 700;
        i{
          font-size:16px;
        }
      }
    }
  }
  .error__text{
    font-size:13px;
  }
  .tab-container ul{
    border-bottom:1px solid #dee2e6;
    li{
      padding:15px;
      width:50%;
      font-weight: 600;
      font-size:14px;
      cursor: pointer;
      &:first-child{
        border-right:1px solid #dee2e6;
      }
      &.active{
        background-color: #D39B2C;
        color:#fff;
      }
    }
  }
`;

export default UserSettings