const EnglishLanguage = {

    delete_account: 'Delete Account',
    delete_account_message: 'In order to delete your account , verify your passowrd.',
    delete_approve: "Approve",
    delete_cancel: "Cancel",
    delete_account_consequences: `<p>Read carefully before deleting your account</p>
  <p style="text-align: left;">Access: By deleting the account, you will lose your full access to the website and all its services.<br />
  Photos: All photos sent through your account will be permanently lost.<br />
  Calls: You will lose all history and calls sent within your account.<br />
  Relationships: All relationships and links with other users acquired through the site will be lost.<br />
  Please consider carefully before you decide to delete your account.</p>
  `,
    filter_users: "filter Users",
    payment: "Payment",
    women: "Women",
    looking_for_new: "Looking For: ",
    men: "Men",
    deleted: "Deleted!",
    delete: "Delete",
    Optin: 'Opt-in SMS',
    Optout: 'Opt-out SMS',
    chat_has_been_deleted: "Chat has been deleted.",
    deleteCnfrm: 'Are you sure want to delete?',
    logoutCnfrm: 'Are you sure want to Logout?',
    cancelConfirmTitle: "Are you sure you want to cancel?",
    cancelConfirmDesc: "Once you cancel your premium subscription, you will lose access to your calls, your lists and all the benefits and options a premium subscription gives you.",
    cancelConfirmBtn: "Yes I'm sure",
    cancelDeclineBtn: "No! I want to stay premium",
    no_cancel: 'No, cancel!',
    delete_it: 'Yes, delete it!',
    delete_all: 'Delete all',
    select_from_gallery: "Select from Gallery",
    upload_from_device: "Upload from device",
    chat: 'chat',
    gallery: 'Gallery',
    card_number: "Card Number",
    next_renew: "Next Renewal on",
    cancel_subscription: "Cancel Subscription",
    premium: "Premium",
    per_month: "Per month",
    age: "Age",
    location: "Location",
    reset_all: "Reset All",
    search_name: "Search by name",
    looking_for: "Looking for",
    home: "home",
    messages: "messages",
    discover: "discover",
    favourite: "favourite",
    upgrade_premium: "Upgrade to premium",
    upgrade_premium_subtext: "Upgrade to a premium subscription for free to send unlimited messages",
    logout: "logout",
    select: "Select",
    profile: "Profile",
    english: "english",
    hebrew: "hebrew",
    settings: "settings",
    subscription: "Subscription",
    image: "image",
    email: "email",
    username: "username",
    gender: "gender",
    city: "city",
    date_birth: "Date of birth",
    height: "height",
    rel_status: "Relationship status",
    loading: "Loading...",
    rel_purpose: "Purpose of the relationship",
    body_type: "Body type",
    hair_color: "Hair color",
    eye_color: "Eye color",
    my_occupation: "My occupation",
    description: "Description",
    drink: "drink",
    smoke: "smoke",
    hobbies: "Hobbies",

    do_you_smoke: "Do you smoke?",
    do_you_alcohal: "Do you drink alcohol?",

    upload_gallery: "Upload gallery",
    about: "About",
    age_range: "Age range",
    maximum_6: "Upload maximum 6 files here",
    update: "Update",
    both: "both",
    click_start_chat: "Click any user to Start Chat",
    type_message: "Type a message...",
    online_users: "Online users",
    occupation: "Occupation",
    search: "Search",
    save: "Save",
    smoke: "Smoke",
    alcohol: "Alcohol",
    user_profile: "User profile",
    current_location: "Current Location",
    relationship_purpose: "Relationship purpose",
    diet: "Diet",
    children: "Children",
    pets: "Pets",
    download: "Download",
    date: "Date",
    languages: "Languages",
    education: "Education",
    religion: "Religion",
    sign: "Sign",
    edit_profile_edit: 'Edit profile',
    send_message: "Send Message",
    up_liked: "Access to the highest quality women who have passed strict verification",
    up_ph: "",
    up_visited: "Increased exposure in the message list and appearance on the favourite page with increased exposure",
    up_ur: "Sending unlimited messages",
    get_started: "Upgrade to Premium",
    get_started_already_paid: "Subscription renewal",
    vip_upgrade: "Try for NIS 0.99 for 24 hours,without obligation,you can cancel at any time.",
    googlePayComplete: "Please complete the payment on Google Pay",
    applePayComplete: "Please complete the payment on Apple Pay",
    vip_upgrade_mobile: "Try the premium subscription for free",
    premium_upgrade: "Upgrade to premium",
    premium_upgrade_message: "Upgrade to premium to get unlimited access to all features",
    premium_upgrade_message_mobile: "Upgrade to premium to get unlimited access to all features",
    onFreeTrial: "You are on a free trial",
    subscriptionCancelled: "Subscription cancelled",

    premium_btn_title: 'Try Premium for Free',
    free: "Free",
    subscription_status: "Subscription status",
    subscription_expiry: "Subscription expiry",
    delete_my_account_text: "Delete my account",
    account_delete_confirm: "Are you sure you want to delete your account?",
    yes_delete: "Yes, delete",
    account_deleted_text: "Your account has been deleted",
    settings: "Settings",
    user_settings: "User settings",
    edit: "Edit",
    saved_card: "Save card information for monthly automatically purchase subscription",
    vip_charges: "After the 7-day free trial, your subscription will auto-renew for",
    update_package: "0.99 NIS for 24 hours, without obligation, can be canceled at any time.",
    update_premium_package: "Only 299 NIS per month. Without obligation, you can cancel at any time.",
    update_package_discreet: 'Discreet subscription - we will use the name "Yellow" on the credit charge, without any mention of dating or the site.',

    package_expire: "every 2 month",
    skip: "Skip",
    max_age: "max age",
    min_age: "min age",
    apply_filter: "apply filters",
    edit_profile: "Edit profile",
    login: "Login",
    password: "Password",
    forget_password: "Forgot your password?",
    password_reset: "password reset",
    edit_password: "Edit password",
    current_password: 'Current password',
    new_password: 'New password',
    save_changes: 'Save Changes',

    register: "Sign Up",
    click_to_start: "By clicking the login button, you agree to our terms and conditions.",
    click_to_create: "By clicking the sign up button, you agree to our terms and conditions.",
    cancel: "Cancel",
    reset_password: "Reset Password",
    success_reset: "We've sent you an email to reset password",
    email_reset_alert: "If it doesn't arrive, check your spam folder, or try another email address",
    c_password: "Confirm Password",
    male: "Male",
    female: "Female",
    create_account: "Create a new account",
    chat_users: "Chat users",
    cm: "cm",
    // save_card:"Save card information for monthly automatically purchase subscription",
    save_card: "After the trial period, subscription cost is 299 ILS, billed automatically.",
    valid_date: "Exp Date",
    cvc: "CVV",
    name: "Full Name",
    id: "ID",
    submit: "Start The Free Trial",
    submitPayment: "Upgrade to Premium",
    reset: "Reset",
    exploar_users: "Haven't liked anyone yet? Return to the Home Page to find the one !",
    copyright: 'All rights reserved to jojo.co.il ©',
    terms_conditions: "Terms & Conditions",
    privacy_policy: "Privacy Policy",
    change_height_hint: "Change height?",


    cities: {
        af: "North District",
        ar: "Haifa District",
        ari: "Tel Aviv District",
        ash: "Central District",
        ashk: "Jerusalem District",
        baqa: "South District",
    },

    // {
    //   af:"Afula",
    //   ar:"Arad",
    //   ari:"Ariel",
    //   ash:"Ashdod",
    //   ashk:"Ashkelon",
    //   baqa:"Baqa Jatt",
    //   bat:"Bat Yam",
    //   beer:"Beersheba",
    //   beit:"Beit She'an",
    //   beits:"Beit Shemesh",
    //   beitar:"Beitar Illit",
    //   bnei:"Bnei Brak",
    //   dim:"Dimona",
    //   eilt:"Eilat",
    //   el:"El'ad",
    //   giv:"Giv'atayim",
    //   givat:"Giv'at Shmuel",
    //   had:"Hadera",
    //   haifa:"Haifa",
    //   herz:"Herzliya",
    //   hod:"Hod HaSharon",
    //   holom:"Holon",
    //   jer:"Jerusalem",
    //   kar:"Karmiell",
    //   kafr:"Kafr Qasim",
    //   kafr_s:"Kfar Saba",
    //   kiryat:"Kiryat Ata",
    //   kiryat_b:'Kiryat Bialik',
    //   kiryat_g:"Kiryat Gat",
    //   kiryat_m:"Kiryat Malakhi",
    //   kiryat_mot:"Kiryat Motzkin",
    //   kiryat_ono:"Kiryat Ono",
    //   kiryat_sh:"Kiryat Shmona",
    //   kiryat_y:"Kiryat Yam",
    //   lod:"Lod",
    //   ma_ale:"Ma'ale Adumim",
    //   ma_alot:"Ma'alot-Tarshiha",
    //   migd:"Migdal HaEmek",
    //   modi_in:"Modi'in Illit",
    //   modi_m_r:"Modi'in-Maccabim-Re'ut",
    //   naha:"Nahariya",
    //   naza:"Nazareth",
    //   nesh:"Nesher",
    //   ness:"Ness Ziona",
    //   netan:"Netanya",
    //   netivot:"Netivot",
    //   ofakim:"Ofakim",
    //   or_akiva:"Or Akiva",
    //   or_yeh:"Or Yehuda",
    //   patah_ti:"Petah Tikva",
    //   qalans:"Qalansawe",
    //   raanana:"Ra'anana",
    //   rahat:"Rahat",
    //   ramat:"Ramat Gan",
    //   ramat_ha:"Ramat HaSharon",
    //   ramla:"Ramla",
    //   rehovot:"Rehovot",
    //   ris_le:"Rishon LeZion",
    //   rosh_ha:"Rosh HaAyin",
    //   safed:"Safed",
    //   sakhnin:"Sakhnin",
    //   sderot:"Sderot",
    //   sas:"Shefa 'Amr (Shfar'am)",
    //   tamra:"Tamra",
    //   tayibe:"Tayibe",
    //   tel_aviv:"Tel Aviv",
    //   tiberias:"Tiberias",
    //   tira:"Tira",
    //   tirat_carmel:"Tirat Carmel",
    //   umm_al:"Umm al-Fahm",
    //   yavne:"Yavne",
    //   yeh_mono:"Yehud Monosson",
    //   yokn:"Yokneam",
    // },


    relations: {
        monogamous_single: 'Single',
        married: 'Married',
        divorced: 'Divorced',
        open_relation: 'Open RelationShip',
    },
    purposes: {
        Friendship: 'Friendship',
        LongTermDating: 'long-term dating',
        shortTermDating: 'short-term dating',
        Hookups: 'Hookups',
    },
    bodies: {
        thin: 'Thin',
        fit: 'Fit',
        in_shape: 'In Shape',
        muscular: 'Muscular',
        average_build: 'Average Build',
        few_extra: 'Few Extra Kilos',
        chubby_juicy: 'Chubby & Juicy',
    },

    hairColors: {
        black: 'Black Hair Color',
        brown: 'Brown Hair Color',
        blonde: 'Blonde Hair Color',
        redhead: 'Redhead Hair Color',
        gray: 'Gray Hair Color',
        bold: 'Bold',
        lightbrown: 'Light-brown hair',
    },
    eyeColors: {
        brown: "Brown Eye Color",
        darkBrown: "Dark Brown Eye Color",
        green: "Green Eye Color",
        blue: "Blue Eye Color",
    },
    kids: {
        doesnothave: "No Kids",
        haskids: "Has Kids",
        rathernotsay: "Rather Not Mention",
    },

    speaks: {
        a: "Hebrew",
        b: "English",
        c: "Russian",
        d: "Arabic",
        e: "Spanish",
        f: "French",
    },
    qualifications: {
        a: "Secondary Education",
        b: "Professional certificate",
        c: "Student",
        d: "Undergraduate Degree",
        e: "MA Degree",
        f: "Doctoral",
        g: "A school of life",
    },
    jobs: {
        a: "Full-time Employee",
        b: "Part-time employee",
        c: "Freelancer",
        d: "Self-employed",
        e: "Unemployed",
        f: "Pension",
    },
    religiouses: {
        a: "Atheism",
        b: "Christianity",
        c: "Judaism",
        d: "Catholicism",
        e: "Islam",
        f: "Other religion",
    },
    stars: {
        a: "Aries",
        b: "Taurus",
        c: "Gemini",
        d: "Cancer",
        e: "Leo",
        f: "Vigro",
        g: "Libra",
        h: "Scorpio",
        i: "Sagittaruis",
        j: "Capricornus",
        k: "Aquarius",
        l: "Pisces",
    },
    cigrates: {
        a: "Smoke cigarettes regularly",
        b: "Smoke cigarettes sometimes",
        c: "Doesn't smoke cigarettes",
    },
    drinks: {
        a: "Drinks often",
        b: "Drinks sometimes",
        c: "Doesn't drink",
    },
    hobbiesList: {
        a: "Flights and trips",
        b: "Sports",
        c: "Photography",
        d: "Clubs and parties",
        e: "Luxury cars",
        f: "Politics",
        g: "Cinema",
        h: "Sailing and Cruises",
        i: "Restaurants and Food",
        j: "Luxury Hotels",
        k: "Luxury Entertainment",
        l: "Relaxation"
    },
    foods: {
        a: "Vegetarian",
        b: "Vegan",
        c: "Kosher",
        d: "Halal",
        e: "Gluten free",
    },
    basic_profile: "Basics",
    no_users: "No Users Found",
    looks: "Looks",
    family: "Family",
    life_style: "Life Style",
    background: "Background",
    validations: {
        email_required: "Email is a required field",
        password_required: 'Password is a requied field',
        new_password: 'New password is a required field',
        current_required: 'Current password is a requied field',
        email_valid: "Email must be a valid email",
        password_min: "Password must be at least 6 characters",
        username_required: "Username is a required field",
        username_min: "Username must be at least 3 characters",
        username_max: "Username must be at most 20 characters",
        confirm_password: "Confirm password did not match",
        confirm_password_min: "Confirm password must be at least 6 characters",
        confirm_password_required: "Confirm password is a required field",
        birth_date: "Please select your birth date",
        age_limit: "Your age is not between 18 to 100 years",
        gender_required: "Gender is a required field",
        city_required: "City is a required field",
    },
    tabs: {
        all_user: "All users",
        spotlight: "Spotlight",
        online: "Online",
        new: "New"
    },
    contact_us: "Contact Us",
    phone: "phone",
    contactFormSubmit: "Submit",
    contactFormSubmitting: "Sending...",
    contactFormSubmited: "Submited",
    contactFormFaild: "Something is Wrong",
    contactFormSuccess: "Request received. We will be in touch shortly",
    contactFormFname: "Full name required",
    contactFormPhone: "Phone required",
    contactFormEmail: "Email required",
    contactFormMessage: "Message required",
    terms_conditions_data: `<p>Terms of Use for jojo.co.il</p>
<p><br />
  1. Acceptance of Terms By accessing and using jojo.co.il, you agree to be bound by these Terms of Use ("Terms"). If you do not agree to these Terms, you must not use our services.<br />
  2. Eligibility You must be at least 18 years of age to use jojo.co.il. By using jojo.co.il, you represent and warrant that you have the right, authority, and capacity to enter into these Terms.<br />
  3. User Conduct You are responsible for your conduct while using jojo.co.il and for any content you provide, including but not limited to photos, messages, and profile information. You agree not to engage in any conduct that is unlawful, misleading, harmful, abusive, harassing, defamatory, vulgar, obscene, or otherwise objectionable.<br />
  4. Privacy and Data Protection jojo.co.il is committed to protecting your privacy. Our Privacy Policy outlines our practices concerning the collection, use, and disclosure of your information.<br />
  5. Intellectual Property All content on jojo.co.il, including text, graphics, logos, and software, is the property of jojo.co.il or its content suppliers and is protected by copyright and other intellectual property laws.<br />
  6. Disclaimer of Warranties jojo.co.il is provided on an "as is" and "as available" basis. We do not guarantee that our service will be uninterrupted or error-free, and we do not make any warranty as to the results that may be obtained from the use of the service.<br />
  7. Limitation of Liability jojo.co.il will not be liable for any damages of any kind arising from the use of this site, including, but not limited to direct, indirect, incidental, punitive, and consequential damages.<br />
  8. Changes to Terms of Use jojo.co.il reserves the right to modify these Terms at any time. We will notify users of any changes by posting the new Terms on the site. Your continued use of the service after any such changes constitutes your acceptance of the new Terms.<br />
  9. Governing Law These Terms shall be governed by and construed in accordance with the laws of the State of Wyoming, USA, without giving effect to any principles of conflicts of law.<br />
 10. Contact Us If you have any questions about these Terms, please contact us at legal@jojo.co.il</p>
  
  <p><br />
  &nbsp;</p>
  `,
    privacy_policy_data: `<p>PRIVACY POLICY</p>

  <p><br />
  Welcome to jojo.co.il ("Website"). We respect your privacy and are committed to protecting your personal data. This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you visit our website.<br />
  1. INFORMATION WE COLLECT<br />
  We may collect the following types of information:<br />
  Personal Identification Information: Name, email address, telephone number, and other contact details you provide when registering on our site or participating in interactive features of the service.<br />
  Usage Data: Information on how the service is accessed and used. This may include details such as your computer's Internet Protocol address (e.g., IP address), browser type, browser version, our service pages that you visit, the time and date of your visit, the time spent on those pages, and other diagnostic data.<br />
  Cookies and Tracking Data: We use cookies and similar tracking technologies to track activity on our service and we hold certain information. Cookies are files with a small amount of data which may include an anonymous unique identifier.<br />
  2. HOW WE USE YOUR INFORMATION<br />
  We use the collected data for various purposes:<br />
  To provide and maintain our service<br />
  To notify you about changes to our service<br />
  To allow you to participate in interactive features when you choose to do so<br />
  To provide customer support<br />
  To gather analysis or valuable information so that we can improve our service<br />
  To monitor the usage of our service<br />
  To detect, prevent, and address technical issues<br />
  To provide you with news, special offers, and general information about other goods, services, and events which we offer<br />
  3. Sharing Your Information<br />
  We may share your personal information with third parties under the following conditions:<br />
  Service Providers: We may share your information with third-party companies that perform services on our behalf, such as data analysis, email delivery, hosting services, customer service, and marketing assistance.<br />
  Legal Obligations: We may disclose your information where required to do so by law or in response to valid requests by public authorities (e.g., a court or a government agency).<br />
  4. Security of Your Information<br />
  We take the security of your personal information seriously and implement appropriate technical and organizational measures to protect it against unauthorized or unlawful processing and against accidental loss, destruction, or damage.<br />
  5. International Data Transfers<br />
  Your information, including Personal Data, may be transferred to — and maintained on — computers located outside of your state, province, country, or other governmental jurisdiction where the data protection laws may differ from those of your jurisdiction.<br />
  6. Your Data Protection Rights<br />
  You have certain data protection rights, including the right to access, correct, update, or request deletion of your personal information.<br />
  7. Changes to This Privacy Policy<br />
  We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page.</p>
  8. Contact Us<br />
  If you have any questions about this Privacy Policy, please contact us at legal@jojo.co.il. </br>
  
  <p><br />
  &nbsp;</p>
  `
}

export default EnglishLanguage