const HebrewLanguage = {

    delete_account: 'מחק חשבון',
    delete_account_message: 'על מנת למחוק את חשבונך, אמת את סיסמתך',
    delete_approve: "ביטול",
    delete_cancel: "אישור",
    delete_account_consequences: `<p>קרא בזהירות לפני מחיקת החשבון שלך</p>
    <p style="text-align:right;">.גישה: במחיקת החשבון, תאבד את גישתך המלאה לאתר ולכל השירותים שלו<br />
    .תמונות: כל התמונות שנשלחו דרך החשבון שלך יאבדו לצמיתות<br />
    .שיחות: תאבד את כל ההיסטוריה והשיחות שנשלחו במסגרת החשבון שלך<br />
    .קשרים: כל קשרים וקישורים עם משתמשים אחרים שנרכשו באמצעות האתר יאבדו<br />
    .אנא שקל/י בקפידה לפני שאת/ה מחליט/ה למחוק את החשבון שלך</p>
    `,
    home: "ראשי",
    payment: "תשלום",
    next_renew: "החידוש הבא יתחיל",
    cancel_subscription: "בטל רישום",
    deleteCnfrm: '? האם בטוח למחוק את השיחה ',
    logoutCnfrm: '?האם ברצונך להתנתק',
    cancelConfirmTitle: " האם אתה בטוח שאתה מעוניין לבטל?",
    cancelConfirmDesc: " ברגע שתבטל את מנוי הפרימיום שלך, תאבד גישה לשיחות שלך, לרשימות שלך ולכל ההטבות והאפשרויות של מנוי פרימיום נותן לך.",
    cancelConfirmBtn: "כן אני בטוח ",
    cancelDeclineBtn: " לא! אני רוצה להישאר פרימיום",
    chat: 'לְשׂוֹחֵחַ',
    Optin: 'הסכמה ל-SMS',
    Optout: 'ביטול הסכמה ל-SMS',
    deleted: '!נמחק',
    delete: 'מחק',
    chat_has_been_deleted: "הצ'אט נמחק.",
    card_number: 'מספר כרטיס',
    name: "שם מלא",
    premium: "פרימיום",
    per_month: "לחודש",
    men: 'גברים',
    women: 'נשים',
    no_users: "לא נמצאו תוצאות",
    messages: "הודעות",
    discover: "לְגַלוֹת",
    no_cancel: " !לא, בטל",
    delete_it: " !כן, מחק את זה",
    delete_all: "מחק הכל",
    select_from_gallery: "בחר מהגלריה",
    upload_from_device: "העלה מהמכשיר",
    favourite: "מועדפים",
    filter_users: "סינון",

    profile: "פרופיל",
    upgrade_premium: "שדרגו לפרימיום",
    upgrade_premium_subtext: "שדרג למנוי פרימיום בחינם בכדי לשלוח הודעות ללא הגבלה",
    free: "חינם",
    subscription_status: "מצב המנוי",
    subscription_expiry: "תוקף המנוי",
    delete_my_account_text: "מחק את חשבוני",
    account_delete_confirm: "?האם אתה בטוח שברצונך למחוק את חשבונך",
    yes_delete: "כן, למחוק",
    account_deleted_text: "החשבון שלך נמחק",
    settings: "הגדרות",
    logout: "התנתקות",
    user_settings: "הגדרות משתמש",
    saved_card: "שמור פרטי כרטיס לרכישה אוטומטית חודשית של מנוי",
    edit: "עריכה",
    age: "גיל",
    subscription: "מנוי",
    settings: "הגדרות",
    // location:"מיקום",
    location: "עיר מגורים",
    reset_all: "איפוס",
    search_name: "חפש לפי שם",
    download: "הורד",
    date: "תאריך",
    looking_for: "מחפש",
    looking_for_new: "מחפש/ת:",
    select: "בחר",
    gallery: 'גלריה',
    hebrew: "עִברִית",
    english: "אנגלית",
    image: "תמונה",
    email: "אימייל",
    username: "שם משתמש",
    gender: "מִין",
    age_range: "טווח גילאים",
    both: "שניהם",
    city: "אזור",
    date_birth: "תאריך לידה",
    height: "גובה",
    rel_status: "סטטוס מערכת יחסים",
    update_package: '0.99 ש"ח ל-24 שעות, ללא התחייבות, ניתן לבטל בכל עת.',
    update_premium_package: "רק 299 ₪ לחודש. ללא התחייבות, ניתן לבטל בכל עת.",
    googlePayComplete: "נא להשלים את התשלום ב-Google Pay",
    applePayComplete: "נא להשלים את התשלום ב-Apple Pay",
    update_package_discreet: 'מנוי דיסקרטי - אנו נשתמש בשם "Yellow" בחיוב האשראי, ללא שום אזכור להיכרויות או לאתר.',

    looks: "מראה",
    family: "משפחה",
    background: "רקע כללי",
    life_style: "סגנון חיים",
    diet: "דיאטה",
    skip: "לדלג",
    loading: "טוען...",
    rel_purpose: "מטרת הקשר",
    body_type: "מבנה גוף",
    hair_color: "צבע שיער",
    eye_color: "צבע עיניים",
    my_occupation: "העיסוק שלי",
    occupation: "תעסוקה",
    description: "תיאור",
    do_you_smoke: "סיגריות",
    do_you_alcohal: "אלכוהול",

    drink: "הרגלי שתייה",
    smoke: "עישון",
    hobbies: "תחביבים",

    upload_gallery: "העלה גלריה",
    about: "קצת עליי",
    cm: 'ס"מ',
    maximum_6: "העלה מקסימום 6 קבצים לכאן",
    update: "עדכון",
    click_start_chat: "לחץ על משתמש כלשהו כדי להתחיל צ'אט",
    type_message: "הקלד הודעה...",
    online_users: "משתמשים ברשת",
    search: "חיפוש",
    smoke: "עישון",
    alcohol: "כּוֹהֶל",
    current_location: "מיקום נוכחי",
    user_profile: "פרופיל משתמש",
    relationship_purpose: "מטרת מערכת היחסים",
    send_message: "לשלוח הודעה",
    up_liked: "חשיפה מוגברת ברשימת ההודעות וקידום לתחילת תוצאות החיפוש",
    // up_ph:"Commodo platea placerat nibh facilisi",
    up_ph: "",
    up_visited: "גישה לנשים האיכותיות ביותר שעברו אימות קפדני",
    up_ur: "שליחת הודעות ללא הגבלה",
    get_started: 'שדרג לפרמיום',
    get_started_already_paid: "חידוש המנוי",
    premium_upgrade: "שדרג לפרימיום",
    premium_upgrade_message: "שדרג לפרימיום כדי לקבל גישה בלתי מוגבלת לכל התכונות",
    premium_upgrade_message_mobile: "שדרג לפרימיום כדי לקבל גישה בלתי מוגבלת לכל התכונות",
    onFreeTrial: "אתה בניסיון חינם",
    subscriptionCancelled: "הרישום בוטל",
    vip_upgrade: 'נסו ב-0.99 ש"ח ל-24 שעות, ללא התחייבות, ניתן לבטל בכל עת.',
    vip_upgrade_mobile: "נסו את מנוי הפרמיום בחינם",
    premium_btn_title: 'נסו את מנוי הפרימיום בחינם',
    vip_charges: "לאחר 7 ימי הניסיון בחינם, המנוי שלך יתחדש אוטומטית תמורת",
    package_expire: "כל חודשיים",
    max_age: "גיל מקסימלי",
    min_age: "גיל מינימלי",
    apply_filter: "חיפוש",
    edit_profile: "ערוך פרופיל",
    edit_profile_edit: "עריכת פרופיל",
    pets: "חיות מחמד",
    languages: "שפות",
    education: "השכלה",
    religion: "דת",
    sign: "מזל",
    children: "ילדים",
    login: "התחברות",
    password: "סיסמה",
    forget_password: "שכחת את הסיסמה?",
    register: "הרשמה",
    click_to_start: "בלחיצה על כפתור התחברות , את/ה מסכים לתנאים וההגבלות שלנו.",
    click_to_create: "בלחיצה על כפתור ההרשמה, אתה מסכים לתנאים וההגבלות שלנו.",
    password_reset: "איפוס סיסמה",
    edit_password: "עריכת סיסמה",
    current_password: 'סיסמה נוכחית',
    new_password: 'סיסמה חדשה',
    save_changes: 'שמור שינויים',
    cancel: "ביטול",
    reset_password: "איפוס סיסמה",
    success_reset: "שלחנו לך אימייל לסיסמת האיפוס שלך",
    email_reset_alert: 'אם הוא לא מגיע, בדוק בתיקיית הספאם שלך, או נסה כתובת דוא"ל אחרת',
    c_password: "אשר סיסמה",
    male: "גבר",
    female: "אישה",
    create_account: "יצירת חשבון חדש",
    chat_users: "משתמשי צ'אט",
    save: "שמירה",
    valid_date: "תוקף כרטיס",
    cvc: "3 ספרות בגב הכרטיס",
    id: "ת.ז",
    // save_card:"שמור פרטי כרטיס לרכישה אוטומטית חודשית של מנוי",
    save_card: 'לאחר תקופת הניסיון, עלות המנוי הינה 299 ש"ח בחיוב אוטומטי.',
    submit: "התחילו את הניסיון בחינם",
    submitPayment: "שדרג לפרימיום",
    // exploar_users:"לא אהבת אף משתמש ללכת לבית ולחקור משתמשים",
    exploar_users: "עדיין לא אהבתם אף אחת? חזרו לדף הראשי כדי למצוא את האחת",

    reset: "איפוס",
    copyright: 'כל הזכויות שמורות ל jojo.co.il ©',
    terms_conditions: "תנאים והגבלות",
    privacy_policy: "מדיניות הפרטיות",
    change_height_hint: "סמן כדי לשנות גובה",
    cities: {
        af: "אזור הצפון",
        ar: "אזור חיפה",
        ari: "אזור תל אביב",
        ash: "אזור המרכז",
        ashk: "אזור ירושלים",
        baqa: "אזור הדרום",
    },

    // {
    //     af:"עפולה",
    //     ar:"ערד",
    //     ari:"אריאל",
    //     ash:"אשדוד",
    //     ashk:"אשקלון",
    //     baqa:"באקה ג'אט",
    //     bat:"בת ים",
    //     beer:"באר שבע",
    //     beit:"בית שאן",
    //     beits:"בית שמש",
    //     beitar:"ביתר עילית",
    //     bnei:"בני ברק",
    //     dim:"דימונה",
    //     eilt:"אילת",
    //     el:"אלעד",
    //     giv:"גבעתיים",
    //     givat:"גבעת שמואל",
    //     had:"חדרה",
    //     haifa:"חיפה",
    //     herz:"הרצליה",
    //     hod:"הוד השרון",
    //     holom:"חולון",
    //     jer:"ירושלים",
    //     kar:"כרמיאל",
    //     kafr:"כפר קאסם",
    //     kafr_s:"כפר סבא",
    //     kiryat:"קרית אתא",
    //     kiryat_b:'קריית ביאליק',
    //     kiryat_g:"קרית גת",
    //     kiryat_m:"קריית מלאכי",
    //     kiryat_mot:"קריית מוצקין",
    //     kiryat_ono:"קריית אונו",
    //     kiryat_sh:"קריית שמונה",
    //     kiryat_y:"קריית ים",
    //     lod:"לוד",
    //     ma_ale:"מעלה אדומים",
    //     ma_alot:"מעלות-תרשיחא",
    //     migd:"מגדל העמק",
    //     modi_in:"מודיעין עילית",
    //     modi_m_r:"מודיעין-מכבים-רעות",
    //     naha:"נהריה",
    //     naza:"נצרת",
    //     nesh:"נשר",
    //     ness:"נס ציונה",
    //     netan:"נתניה",
    //     netivot:"נתיבות",
    //     ofakim:"אופקים",
    //     or_akiva:"או עקיבא",
    //     or_yeh:"אור יהודה",
    //     patah_ti:"פתח תקווה",
    //     qalans:"קלנסווה",
    //     raanana:"רעננה",
    //     rahat:"רהט",
    //     ramat:"רמת גן",
    //     ramat_ha:"רמת השרון",
    //     ramla:"רמלה",
    //     rehovot:"רחובות",
    //     ris_le:"ראשון לציון",
    //     rosh_ha:"ראש העין",
    //     safed:"צפת",
    //     sakhnin:"סכנין",
    //     sderot:"שדרות",
    //     sas:"שפע עמר (שפרעם)",
    //     tamra:"תמרה",
    //     tayibe:"טייבה",
    //     tel_aviv:"תל אביב",
    //     tiberias:"טבריה",
    //     tira:"טירה",
    //     tirat_carmel:"טירת כרמל",
    //     umm_al:"אום אל פאחם",
    //     yavne:"יבנה",
    //     yeh_mono:"יהוד מונוסון",
    //     yokn:"יקנעם",
    // },

    validations: {
        email_required: 'דוא"ל הוא שדה חובה',
        password_required: 'סיסמה היא שדה חובה',
        current_password: 'הסיסמה הנוכחית היא שדה חובה',
        email_valid: "האימייל חייב להיות אימייל חוקי",
        password_min: "סיסמא חייבת להיות לפחות בת שִׁשָׁה תווים",
        new_password: "אישור הוא שדה חובה",
        username_required: "שם משתמש הוא שדה חובה",
        username_min: "שם המשתמש חייב להיות לפחות שְׁלֹשָׁה תווים",
        username_max: "שם המשתמש חייב להיות באורך עֶשְׂרִים תווים לכל היותר",
        confirm_password: "אשר שהסיסמה לא תואמת",
        confirm_password_min: "אשר את הסיסמה חייבת להיות לפחות שִׁשָׁה תווים",
        confirm_password_required: "אשר את הסיסמה היא שדה חובה",
        birth_date: "אנא בחר את תאריך הלידה שלך",
        age_limit: "הגיל שלך אינו בין שְמוֹנֶה-עֶשְׂרֵה ל-מֵאָה שנים",
        gender_required: "מין הוא שדה נדרש",
        city_required: "עיר הוא שדה חובה",
    },
    relations: {
        monogamous_single: 'רווק/ה',
        married: 'נשוי/אה',
        divorced: 'גרוש/ה',
        open_relation: 'במערכת יחסים פתוחה',
        // Engaged:'Engaged',
        // Married:'Married',
        // open_to_either:'Open to either',
    },
    purposes: {
        Friendship: 'חברות',
        LongTermDating: 'מערכת יחסים טווח רחוק',
        shortTermDating: 'מערכת יחסים טווח קצר',
        Hookups: 'קשר ללא מחויבות',
    },
    bodies: {
        thin: 'רזה',
        fit: 'חטוב/ה',
        in_shape: 'בכושר',
        muscular: 'שרירי',
        average_build: 'מבנה ממוצע',
        few_extra: 'כמה קילו עודפים',
        chubby_juicy: 'מלא/ה ועסיסי/ת',

        // a_little_extra:'A little Extra',
        // Curvy:'Curvy',
    },
    hairColors: {
        black: 'שיער שחור',
        brown: 'שיער חום',
        blonde: 'שיער בלונדיני',
        redhead: "שיער גי'נג'י",
        gray: 'שיער אפור',
        bold: 'קירח',
        lightbrown: 'שיער שטני',
    },
    eyeColors: {
        brown: "עיניים חומות",
        darkBrown: "עיניים כהות",
        green: "עיניים ירוקות",
        blue: "עיניים כחולות",
    },
    kids: {
        doesnothave: "אין ילדים",
        haskids: "יש ילדים",
        rathernotsay: "מעדיף/ה לא לומר",
    },

    speaks: {
        a: "עברית",
        b: "אנגלית",
        c: "רוסית",
        d: "ערבית",
        e: "ספרדית",
        f: 'צרפתית',
    },
    qualifications: {
        a: "השכלה תיכונית",
        b: "תעודה מקצועית",
        c: "סטודנט/ית",
        d: "תואר ראשון",
        e: "תואר שני",
        f: "דוקטורט",
        g: "בית ספר של החיים",
    },
    jobs: {
        a: "שכיר/ה במשרה מלאה",
        b: "שכיר/ה במשרה חלקית",
        c: "פרילנסר/ית",
        d: "עצמאי/ת",
        e: "מובטל/ת",
        f: "פנסיה",
    },
    religiouses: {
        a: "יהודי",
        b: "נוצרי",
        c: "מוסלמי",
        d: "אתאיזם",
        e: "אחר",
        // f: "Other religion",
    },
    stars: {
        a: "מזל טלה",
        b: "מזל שור",
        c: "מזל תאומים",
        d: "מזל סרטן",
        e: "מזל אריה",
        f: "מזל בתולה",
        g: "מזל מאזניים",
        h: "מזל עקרב",
        i: "מזל קשת",
        j: "מזל גדי",
        k: "מזל דלי",
        l: "מזל דגים",
    },
    cigrates: {
        a: "מעשן/ת קבוע",
        b: "מעשן/ת מידי פעם",
        c: "לא מעשן/ת",
    },
    drinks: {
        a: "שותה קבוע",
        b: "שותה מידי פעם",
        c: "לא שותה אלכוהול",
    },
    hobbiesList: {
        a: "טיסות וטיולים",
        b: "ספורט",
        c: "צילום",
        d: "מועדונים ומסיבות",
        e: "מכוניות יוקרה",
        f: "פוליטיקה",
        g: "קולנוע",
        h: "שיט והפלגות",
        i: "מסעדות ואוכל",
        j: "מלונות פאר",
        k: "בילויים יוקרתיים",
        l: "מנוחה וסטלבט"
    },
    foods: {
        a: "צמחוני",
        b: "טבעוני",
        c: "ללא גלוטן",
        d: "כשר",
        e: "אחר",
    },
    basic_profile: "כללי",
    tabs: {
        all_user: "כל המשתמשים",
        spotlight: "המובחרים",
        online: "אונליין",
        new: "חדשים"
    },
    contact_us: "צור קשר",
    phone: "טלפון",
    contactFormSubmit: "שלח",
    contactFormSubmitting: "שְׁלִיחָה...",
    contactFormSubmited: "הוגש",
    contactFormFaild: "משהו לא בסדר",
    contactFormSuccess: "בקשה התקבלה. נהיה בקשר בקרוב",
    contactFormFname: "נדרש שם מלא",
    contactFormPhone: "נדרש טלפון",
    contactFormEmail: "אימייל (חובה",
    contactFormMessage: "דרושה הודעה",
    terms_conditions_data: `<p>תנאי שימוש עבור jojo.co.il</p>


    </br> 1. קבלת התנאים על ידי גישה ושימוש ב-jojo.co.il, אתה מסכים להיות מחויב לתנאי שימוש אלה ("התנאים"). אם אינך מסכים לתנאים אלה, אסור לך להשתמש בשירותים שלנו.
    </br>
2. עליך להיות בן 18 לפחות כדי להשתמש ב-jojo.co.il. על ידי שימוש ב-jojo.co.il, אתה מצהיר ומתחייב שיש לך את הזכות, הסמכות והיכולת להיכנס לתנאים אלה.

    </br> 3. התנהלות משתמש: אתה אחראי להתנהלותך בעת השימוש ב-jojo.co.il ולכל תוכן שאתה מספק, לרבות אך לא רק תמונות, הודעות ופרטי פרופיל. אתה מסכים לא לעסוק בכל התנהגות שהיא בלתי חוקית, מטעה, מזיקה, פוגענית, מטרידה, מכפישה, וולגרית, מגונה או מעוררת התנגדות אחרת.
    </br> 4. פרטיות והגנה על נתונים: jojo.co.il מחויבת להגן על פרטיותך. מדיניות הפרטיות שלנו מתארת את הנהלים שלנו בנוגע לאיסוף, שימוש וחשיפת המידע שלך.
    </br> 5. קניין רוחני: כל התוכן באתר jojo.co.il, לרבות טקסט, גרפיקה, לוגו ותוכנה, הוא רכוש של jojo.co.il או של ספקי התוכן שלה ומוגן על ידי זכויות יוצרים וחוקי קניין רוחני אחרים.
    </br> 6. כתב ויתור על אחריות: jojo.co.il איננו מבטיחים שהשירות שלנו יהיה ללא הפרעות או נקי מטעויות, ואיננו מבטיחים כל אחריות לגבי התוצאות העשויות להתקבל מהשימוש בשירות.
    </br> 7. הגבלת אחריות: jojo.co.il לא תישא באחריות לכל נזק מכל סוג שהוא הנובע מהשימוש באתר זה, לרבות, אך לא רק לנזקים ישירים, עקיפים, מקריים, עונשיים ותוצאתיים.
    </br> 8. שינויים בתנאי השימוש: jojo.co.il שומרת לעצמה את הזכות לשנות תנאים אלו בכל עת. אנו נודיע למשתמשים על כל שינוי על ידי פרסום התנאים החדשים באתר. המשך השימוש שלך בשירות לאחר כל שינוי כזה מהווה את הסכמתך לתנאים החדשים.
    </br> 9. חוק: כל תנאים אלה יהיו כפופים לחוקים של מדינת וויומינג, ארה"ב, ויתפרשו בהתאם, מבלי לתת תוקף לכל עקרונות של סתירות חוק.
    10. צור קשר<br />
    אם יש לך שאלות בנוגע לתנאים הללו, אנא צור קשר איתנו בכתובת legal@jojo.co.il.</p>
    
    <p><br />    `,
    privacy_policy_data: `<p>מדיניות הפרטיות</p>

    <p>מדיניות הפרטיות הזו (&quot;מדיניות&quot;) מתארת איך &quot;Parvaty Holdings LLC&quot; (&quot;החברה&quot;, &quot;אנחנו&quot;, &quot;שלנו&quot; או &quot;לנו&quot;) אוספת, משתמשת, משתפת ומגנה על מידע אישי באתר שלנו Jojo.co.il וביישומון הנייד שלנו (ביחד, &quot;השירותים&quot;). מדיניות זו חלה על אנשים המשתמשים בשירותים שלנו (&quot;המשתמשים&quot;, &quot;אתה&quot; או &quot;הם&quot;).<br />
    1. המידע שאנחנו אוספים<br />
    אנו עשויים לאסוף את הסוגים הבאים של מידע:<br />
    מידע זהות אישית: כולל את שמך המלא, כתובת הדוא&quot;ל, מספר הטלפון הנייד, תאריך הלידה, מין וכל מידע נוסף שאתה מספק בעת יצירת חשבון או עדכון פרופיל.<br />
    תוכן משתמש: כולל את כל המידע שאתה פורסם בפרופיל שלך, הודעות שאתה שולח למשתמשים אחרים וכל תוכן נוסף שאתה מעלה או פורסם דרך השירותים שלנו.<br />
    מכשיר ומידע על השימוש: כולל מידע על אופן השימוש שלך בשירותים שלנו ומידע מהמכשיר שאתה משתמש כדי לגשת לשירותים שלנו.<br />
    2. איך אנחנו משתמשים במידע שלך<br />
    אנחנו עשויים להשתמש במידע שאנחנו אוספים למטרות הבאות:<br />
    ספק, תחזק ושדרג</p>
    
    <p>&nbsp;את השירותים שלנו.<br />
    תקשורת איתך, כולל הגב לתגובותיך או שאלותיך.<br />
    ביצוע מחקר וניתוחים כדי להבין את המשתמשים שלנו ולשפר את השירותים שלנו.<br />
    הפעלת תנאים והגבלותינו, להגן על זכויותינו, הפרטיות, הבטיחות או הרכוש שלנו, ו/או של השותפים שלנו, שלך או של צדדים שלישיים אחרים.<br />
    3. איך אנחנו משתפים את המידע שלך<br />
    אנו לא משתפים את המידע האישי שלך עם צדדים שלישיים ללא הסכמתך, פרט למקרים הבאים:<br />
    ספקי שירותים: אנו עשויים לשתף את המידע שלך עם ספקי שירותים של צד שלישי המשתמשים במידע זה לביצוע שירותים בשמנו, כמו ספקי שירותי אירוח, בודקים, מועצים, יועצים וספקים של שירות ותמיכה ללקוחות.<br />
    אכ&quot;א: אם נדרש על פי חוק, עשויים לחשוף את המידע שלך לגורמי אכ&quot;א או בהליך משפטי.<br />
    מכירה או התמזגות: אנו עשויים לשתף את המידע שלך בקשר עם עסקה תאגידית משמעותית, כמו מכירת העסק שלנו, מכירת נכס, התמזגות, הורדת רכוש או מכירת נכס.<br />
    4. הזכויות</p>
    
    <p>&nbsp;והבחירות שלך<br />
    בהתאם למיקומך, ייתכן ויש לך זכויות מסוימות לגבי המידע האישי שלך, כולל הזכות לגשת אליו, לתקן אותו, לעדכן אותו או לבקש מחיקתו.<br />
    5. פרטיות הילדים<br />
    השירותים שלנו אינם מיועדים לשימוש על ידי ילדים מתחת לגיל 18, ואנו איננו אוספים במידע אישי מילדים מתחת לגיל 18 במידה ולא קיבלנו הסכמת הורים.<br />
    6. שינויים במדיניות הפרטיות שלנו<br />
    אנחנו עשויים לעדכן את מדיניות זו מעת לעת. אם אנחנו עושים שינויים, נודיע לך על ידי עדכון התאריך בראש המדיניות ובמקרים מסוימים, נספק לך הודעה נוספת.<br />
    7. צור קשר<br />
    אם יש לך שאלות בנוגע למדיניות הפרטיות הזו, אנא צור קשר איתנו בכתובת legal@jojo.co.il.</p>
    
    <p><br />
    עודכן לאחרונה: 30/05/2023</p>
    `
}
export default HebrewLanguage